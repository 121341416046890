import { RawDraftContentState } from "draft-js";
import Link from "next/link";
import getContentTree from "rawdraftjs-to-tree";

interface Tree {
  component?: string;
  range?: {
    offset: number;
    length: number;
  };
  data?: any;
  children?: Tree[];
  text?: string;
}

export function getContent(
  content: RawDraftContentState,
  darkBackground: boolean = false
) {
  const tree: Tree = getContentTree(content);
  let id = 0;
  return treeToJSX(tree, id, darkBackground);
}

function consolidateListByType(
  treeArray: Tree[],
  get: "unordered-list-item" | "ordered-list-item"
) {
  let items = [];

  treeArray.forEach((tree, index) => {
    if (items.length === 0 && tree.component === get) {
      items.push(index);
    } else if (
      tree.component === get &&
      index === items[items.length - 1] + 1
    ) {
      items.push(index);
    }
  });

  if (items.length > 0) {
    let children = treeArray.slice(items[0], items[items.length - 1] + 1);

    let newTree: Tree = {
      component: get.replace("-item", ""),
      children: children,
    };

    treeArray.splice(items[0], items.length, newTree);
  }
}

function convertToJSX(treeArray: Tree[], id: number, darkBackground: boolean) {
  if (
    treeArray.some(
      (tree) =>
        tree.component !== "ordered-list-item" &&
        tree.component !== "unordered-list-item"
    )
  ) {
    while (treeArray.some((tree) => tree.component === "ordered-list-item")) {
      consolidateListByType(treeArray, "ordered-list-item");
    }

    while (treeArray.some((tree) => tree.component === "unordered-list-item")) {
      consolidateListByType(treeArray, "unordered-list-item");
    }
  }

  const returnTree = treeArray.map((tree, index) =>
    treeToJSX(tree, id + index, darkBackground)
  );
  return returnTree;
}

export function treeToJSX(tree: Tree, id: number, darkBackground: boolean) {
  const empty = null;
  if (!tree) return null;
  if (!tree.component) return tree.text || empty;
  const thisId = id;
  id++;
  switch (tree.component) {
    case "header-one":
      return (
        <h1
          key={`${thisId}_${tree.component}`}
          className='text-2xl font-bold mt-2 mb-1'>
          {convertToJSX(tree.children, id, darkBackground)}
        </h1>
      );
    case "header-two":
      return (
        <h2
          key={`${thisId}_${tree.component}`}
          className='text-xl font-bold mt-2 mb-1'>
          {convertToJSX(tree.children, id, darkBackground)}
        </h2>
      );
    case "header-three":
      return (
        <h3
          key={`${thisId}_${tree.component}`}
          className='text-lg font-bold mt-2 mb-1'>
          {convertToJSX(tree.children, id, darkBackground)}
        </h3>
      );
    case "BOLD":
      return (
        <b key={`${thisId}_${tree.component}`}>
          {convertToJSX(tree.children, id, darkBackground)}
        </b>
      );
    case "ITALIC":
      return (
        <i key={`${thisId}_${tree.component}`}>
          {convertToJSX(tree.children, id, darkBackground)}
        </i>
      );
    case "UNDERLINE":
      return (
        <u key={`${thisId}_${tree.component}`}>
          {convertToJSX(tree.children, id, darkBackground)}
        </u>
      );
    case "STRIKETHROUGH":
      return (
        <s key={`${thisId}_${tree.component}`}>
          {convertToJSX(tree.children, id, darkBackground)}
        </s>
      );
    case "LINK":
      const isLocalLink = String(tree.data.url).charAt(0) === "/";

      if (isLocalLink) {
        return (
          <Link key={`${thisId}_${tree.component}`} href={tree.data.url}>
            <a className='inline-flex hover:text-green-default break-words'>
              {convertToJSX(tree.children, id, darkBackground)}
            </a>
          </Link>
        );
      } else {
        return (
          <a
            key={`${thisId}_${tree.component}`}
            className='inline-flex text-blue-dark hover:text-red-dark break-all'
            target='_blank'
            href={tree.data.url}>
            {convertToJSX(tree.children, id, darkBackground)}
            <svg
              className='w-4 h-4 my-auto text-red-dark'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'>
              <path d='M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z' />
              <path d='M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z' />
            </svg>
          </a>
        );
      }
    case "blockquote":
      return (
        <blockquote
          key={`${thisId}_${tree.component}`}
          className='pl-2 border-l-4 border-yellow-default italic text-yellow-900 py-2 my-2 bg-orange-100 bg-opacity-60 rounded'>
          {convertToJSX(tree.children, id, darkBackground)}
        </blockquote>
      );
    case "ordered-list":
      return (
        <ol
          key={`${thisId}_${tree.component}`}
          className='list-decimal pl-8 pb-2'>
          {convertToJSX(tree.children, id, darkBackground)}
        </ol>
      );
    case "unordered-list":
      return (
        <ul key={`${thisId}_${tree.component}`} className='pl-8 pb-2'>
          {convertToJSX(tree.children, id, darkBackground)}
        </ul>
      );
    case "ordered-list-item":
      return (
        <li key={`${thisId}_${tree.component}`}>
          {convertToJSX(tree.children, id, darkBackground)}
        </li>
      );
    case "unordered-list-item":
      return (
        <li key={`${thisId}_${tree.component}`}>
          {convertToJSX(tree.children, id, darkBackground)}
        </li>
      );
    case "image":
      return (
        <img
          key={`${thisId}_${tree.component}`}
          className='px-4 py-2'
          src={tree.data.src}
          alt={tree.data.alt || ""}
        />
      );
    case "video":
      return (
        <div
          key={`${thisId}_${tree.component}`}
          className='inline-flex justify-center max-w-full px-4 py-2'
          dangerouslySetInnerHTML={{ __html: tree.data.src }}
        />
      );
    case "root":
      return (
        <div
          key={`${thisId}_${tree.component}`}
          className={`${darkBackground ? "text-white" : ""}`}>
          {convertToJSX(tree.children, id, darkBackground)}
        </div>
      );
    default:
      return (
        <p key={`${thisId}_${tree.component}`} className='pb-2'>
          {convertToJSX(tree.children, id, darkBackground)}
        </p>
      );
  }
}
