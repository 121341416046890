import { UseInputBind } from "lib/hooks/use-input";

export const labelClassNames = "w-full  text-sm font-medium text-gray-700";
export const inputClassNames =
  "mt-1 w-full px-3 py-2 text-base border border-gray-300 focus:outline-none focus:ring-yellow-default focus:border-yellow-default sm:text-sm rounded-md";
export const inputCheckboxClassNames =
  "mt-1 text-base border border-gray-300 focus:outline-none focus:ring-yellow-default focus:border-yellow-default sm:text-sm rounded-md";

export const Label = (props: React.LabelHTMLAttributes<HTMLLabelElement>) => (
  <label {...props} className={`${labelClassNames} ${props.className}`}>
    {props.children}
  </label>
);

export const Input = (props: React.InputHTMLAttributes<HTMLInputElement>) => (
  <input {...props} className={`${inputClassNames} ${props.className}`} />
);

interface InputProps {
  name?: string;
  label: string;
  id: string;
  type: HTMLInputElement["type"];
  bind: UseInputBind;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  className?: string;
}

export const StandardInput = ({
  name,
  label,
  id,
  type,
  bind,
  labelProps,
  inputProps,
  className,
}: InputProps) => {
  return (
    <>
      <Label {...labelProps} className={className ? className : ""}>
        {label}
        <Input id={id} name={name} type={type} {...inputProps} {...bind} />
      </Label>
    </>
  );
};

interface ValidatedInputProps extends InputProps {
  showError?: boolean;
  errorMessage?: any;
  showSuccess?: boolean;
}

export const ValidatedInput = ({
  name,
  label,
  id,
  type,
  showError,
  errorMessage,
  showSuccess = false,
  bind,
  labelProps,
  inputProps,
  className,
}: ValidatedInputProps) => {
  return (
    <>
      <div className={`${className ? className : ""} text-sm`}>
        <Label {...labelProps}>{label}</Label>
        <div className=''>
          <Input
            id={id}
            name={name}
            type={type}
            {...inputProps}
            {...bind}
            className={`${
              showError
                ? "focus:ring-red-default focus:border-red-default border-red-default"
                : showSuccess
                ? "focus:ring-green-default focus:border-green-default border-green-default"
                : "focus:ring-yellow-default focus:border-yellow-default"
            } `}
          />
          {errorMessage && showError && (
            <div className='text-sm mt-2 text-red-800'>
              {typeof errorMessage === "string" ? (
                <p>{errorMessage}</p>
              ) : (
                errorMessage
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
