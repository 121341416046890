import { RenderContent } from "components/content-renderer";
import { AnnouncementSVG } from "components/decorative/announcement-svg";
import { HeartSVG } from "components/decorative/heart-svg";
import { ReviewSVG } from "components/decorative/review-svg";
import { Footer } from "components/layout/footer";
import { Header } from "components/layout/header";
import { useGetAlertsQuery } from "lib/graphql-generated";
import { useEditablePageHook } from "lib/hooks/use-editable-page";
import { useUser } from "lib/hooks/use-user";
import Head from "next/head";
import Link from "next/link";

interface Props {
  className?: string;
  children: any;
  pageName: string;
}

export const Layout = ({ className, children, pageName }: Props) => {
  const { userData } = useUser();
  const userIsSeller = userData?.role === "bookseller";
  const { data: alerts } = useGetAlertsQuery();

  const editablePage = useEditablePageHook();

  return (
    <>
      <Head>
        <title>Lighthouse | {pageName}</title>
      </Head>
      {userIsSeller && (
        <>
          <div className='fixed top z-50 bg-black py-1 px-1 w-full inline-flex space-x-8'>
            <Link href='/admin'>
              <a className='text-white hover:underline'>Admin Dashboard</a>
            </Link>
            {!!editablePage && (
              <Link href={editablePage?.editUrl}>
                <a className='text-white hover:underline'>Edit Page</a>
              </Link>
            )}
          </div>
          <div className='h-8'></div>
        </>
      )}
      {alerts?.unstructured_data_by_pk?.data?.map(({ data, index }) => (
        <span key={`outer_${index}`}>
          {data.map((block, i) => (
            <section
              key={`inner_${index}_${i}`}
              className='max-w-screen-2xl mx-auto bg-red-dark pt-2 px-8 text-center text-white'>
              <h2 className='text-base header'>{block.key}</h2>
              <div className='text-sm'>
                <RenderContent content={block.value} />
              </div>
            </section>
          ))}
        </span>
      ))}
      <Header />
      <main className={` ${className}`}>{children}</main>
      <Footer />
      <ReviewSVG className='h-0' />
      <AnnouncementSVG className='h-0' />
      <HeartSVG className='h-0' />
    </>
  );
};
