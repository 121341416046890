import { Spinner } from "components/spinner";
import Link from "next/link";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonSize?: "sm" | "md" | "lg" | "xl" | "sm-md";
  buttonType?: "primary" | "secondary" | "ghost" | "blank" | "white";
  href?: string;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  buttonSize = "sm",
  buttonType,
  href,
  loading,
  ...props
}) => {
  const getButtonSize = () => {
    switch (buttonSize) {
      case "sm":
        return `px-2.5 ${loading && "pl-8"} pb-0 pt-1 text-xs xl:text-sm`;
      case "md":
        return `px-4 ${loading && "pl-8"} pb-1 pt-2 text-sm`;
      case "lg":
        return `px-6 ${loading && "pl-10"} pb-0.5 pt-1.5 text-base`;
      case "xl":
        return `px-8 ${loading && "pl-12"} pb-2 pt-3 text-lg`;
      default:
        return "";
    }
  };

  const getClassNames = () => {
    switch (buttonType) {
      case "primary":
        return "rounded-lg border border-yellow-default focus:ring-green-default bg-yellow-default hover:bg-yellow-dark hover:border-yellow-dark font-bold header text-black";
      case "secondary":
        return "rounded-full border border-green-default text-white bg-green-default hover:bg-green-dark hover:border-green-dark hover:text-yellow-default focus:ring-green-default font-bold uppercase font-header tracking-wide";
      case "ghost":
        return "rounded-lg border border-gray-800 hover:border-green-default focus:ring-green-default capitalize font-header hover:text-green-default";
      case "white":
        return "rounded-lg border-2 border-white uppercase font-header tracking-wide text-white font-bold hover:bg-white hover:text-black";
      default:
        return "hover:underline focus:ring-green-default border-0 mb-0.5";
    }
  };

  const getLoaderSpacing = () => {
    switch (buttonSize) {
      case "sm":
        return `bottom-1`;
      case "md":
        return `bottom-2`;
      case "lg":
        return `bottom-4`;
      default:
        return `bottom-2`;
    }
  };

  if (!!href) {
    return (
      <Link href={href}>
        <a
          className={`
          transition-all ease-in inline-flex items-center font-medium   focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-600
        ${getButtonSize()} ${getClassNames()} ${
            props.className
          } inline-flex justify-center items-center`}>
          {loading && (
            <span
              className={`my-auto mr-2 absolute left-2 ${getLoaderSpacing()}`}>
              <Spinner size={4} />
            </span>
          )}
          {props.children}
        </a>
      </Link>
    );
  } else {
    return (
      <button
        {...props}
        type='button'
        className={`
        transition-all ease-in inline-flex items-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-600 relative
        ${getButtonSize()} ${getClassNames()} ${
          props.className
        } inline-flex justify-center items-center`}>
        {loading && (
          <span
            className={`my-auto mr-2 absolute left-2 ${getLoaderSpacing()}`}>
            <Spinner size={4} />
          </span>
        )}
        {props.children}
      </button>
    );
  }
};
