import { UseInputBind } from 'lib/hooks/use-input';
import { Spinner } from 'components/spinner';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSiteSearchLazyQuery } from 'lib/graphql-generated';

interface Props {
  bind: UseInputBind;
  reset: any;
  className?: string;
}

export const Searchbar = ({ bind, className, reset }: Props) => {
  const router = useRouter();

  const [loading, setloading] = useState<boolean>(false);
  const canSearch = bind.value.length > 2 && !loading;
  const handleSearch = (e) => {
    setloading(true);
    e.preventDefault();
    if (canSearch) {
      router.push(`/search?q=${bind.value}`);
    }
  };

  useEffect(() => {
    setloading(false);
    reset('');
  }, [router.query]);

  return (
    <form className={`${className}`}>
      <label
        htmlFor='search'
        className={`flex flex-row focus-within:border-green-default border-black border`}>
        <span className='sr-only'>Search</span>
        <input
          type='search'
          name='search'
          id='search'
          {...bind}
          className='h-8 focus:outline-none focus:ring-0  header pt-3 w-full border-none'
          placeholder='Search'
        />
        <button
          disabled={!canSearch}
          onClick={handleSearch}
          type='submit'
          className='relative inline-flex items-center px-2 py-2 border-l border-green-50 text-sm font-medium text-green-dark hover:text-green-very-light bg-green-50 hover:bg-green-default focus:outline-none focus:ring-1 focus:ring-green-default focus:border-green-default disabled:bg-white disabled:cursor-not-allowed'>
          <span className='sr-only'>search</span>
          {!loading ? (
            <svg
              className='w-4'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              />
            </svg>
          ) : (
            <Spinner size={3} />
          )}
        </button>
      </label>
    </form>
  );
};
