import Link from 'next/link';

interface Props {
  href: string;
  label: string;
  selected: boolean;
  size: 'xl' | 'l' | 'md';
}

export const HeaderLink = ({ href, label, selected, size }: Props) => {
  const getSize = () => {
    switch (size) {
      case 'md':
        return 'text-base pt-1';
      case 'l':
        return 'text-xl pt-2 xl:pt-1';
      case 'xl':
        return 'text-4xl pt-2 xl:pt-1';
    }
  };

  return (
    <>
      <Link href={href}>
        <a
          className={`mb-4 ${getSize()} xl:text-base [ xl:border  xl:border-black xl:my-auto align-text-bottom  ${
            selected && 'xl:bg-blue-dark xl:text-white'
          } hover:bg-blue-dark hover:text-white ]`}>
          {label}
        </a>
      </Link>
    </>
  );
};
