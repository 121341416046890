import { FC } from 'react';
import { useEffect, useState } from 'react';

interface Props {}

export const ClientOnly: FC<Props> = (props) => {
  const [isClient, setIsClient] = useState<boolean>(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient ? <>{props.children}</> : null;
};
