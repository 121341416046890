import { useRouter } from "next/router";

import { useUser } from "./use-user";

export const useEditablePageHook = () => {
  const router = useRouter();
  const { userData } = useUser();

  const editableDirs = ["events", "posts", "book", "browse"];
  const editablePages = ["about", "faq", "events"];
  const splitPath = router.pathname.split("/");
  const queryParam =
    splitPath.length > 2 ? splitPath[splitPath.length - 1] : null;

  if (editableDirs.some((page) => splitPath.includes(page)) && queryParam) {
    let adminPageRouteBasePath = editableDirs.find((page) =>
      splitPath.includes(page)
    );

    const routeParam =
      adminPageRouteBasePath === "browse"
        ? router.query["category"]
        : router.query[queryParam.replace(/\[/g, "").replace(/\]/g, "")];

    if (adminPageRouteBasePath === "book") adminPageRouteBasePath = "books";
    if (adminPageRouteBasePath === "browse")
      adminPageRouteBasePath = "categories";

    return {
      editUrl: `/admin/${adminPageRouteBasePath}/${routeParam}`,
    };
  } else if (editablePages.some((page) => splitPath[1].includes(page))) {
    return {
      editUrl: `/admin/pages/${editablePages.find((page) =>
        splitPath[1].includes(page)
      )}`,
    };
  } else if (splitPath[1] === "") {
    return { editUrl: "/admin/pages/home" };
  }
  return null;
};
