import { ChangeEvent, Dispatch, useCallback, useEffect, useState } from "react";

export type UseInputBind = {
  value: any;
  onChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => void;
};

export type ValidationResult = { valid: boolean; message?: string; data?: any };

export type UseInputReturn<T> = {
  initialValue: T;
  value: T;
  setValue: Dispatch<any>;
  isModified: boolean;
  init: (value: T) => void;
  bind: UseInputBind;
  validate: () => Promise<ValidationResult>;
  isValid: boolean;
  isValidating: boolean;
  validation: ValidationResult;
};

export function useInput<T>(
  init: T,
  validateValue: (value: T) => Promise<ValidationResult> | ValidationResult = (
    value
  ) => {
    if (!value) {
      return { valid: false, message: "Please enter a value" };
    }
    return { valid: true };
  },
  validateOnChange: boolean = false
): UseInputReturn<T> {
  const [initialValue, setInitialValue] = useState<T>(init);
  const [value, setValue] = useState<T>(initialValue);
  const [touched, setTouched] = useState<boolean>(false);
  const [validation, setValidation] = useState<ValidationResult>({
    valid: true,
  });
  const [isValidating, setIsValidating] = useState<boolean>(false);

  const validate = async () => {
    setIsValidating(true);
    const validationRes = await validateValue(value);
    setIsValidating(false);
    setValidation(validationRes);

    return validationRes;
  };

  useEffect(() => {
    if (!touched) {
      setTouched(true);
    }
    if (validateOnChange && touched) {
      validate();
    }
    setValidation({ valid: true });
  }, [value]);

  return {
    initialValue,
    value,
    setValue,
    isModified: initialValue !== value,
    validate,
    isValid: validation.valid,
    isValidating,
    validation,
    init: (v: T) => {
      setTouched(false);
      setInitialValue(v);
      setValue(v);
    },
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value as any);
      },
    },
  };
}

export const inputsAreValid = (inputs: Array<UseInputReturn<any>>) => {
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].validation.valid) {
      return false;
    }
  }
  return true;
};

export const someInputsAreModified = (inputs: Array<UseInputReturn<any>>) => {
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].isModified) {
      return true;
    }
  }
  return false;
};

export const allInputsAreModified = (
  inputs: Array<UseInputReturn<any>>
): boolean => {
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].isModified) {
      return false;
    }
  }
  return true;
};
