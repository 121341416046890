interface Props {
  className: string;
  url: string;
  text: string;
}

export const SharingBar = ({ className, text, url }: Props) => (
  <>
    <a
      target='_blank'
      href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//lighthousebookshop.com%3A3000${url}`}>
      <svg
        className={className}
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth='2'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z' />
      </svg>
    </a>
    <a
      target='_blank'
      href={`http://twitter.com/share?text=${encodeURIComponent(
        `${text} @lighthousebks\n\n`
      )}&url=https://lighthousebookshop.com${url}`}>
      <svg
        className={className}
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth='2'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z' />
      </svg>
    </a>
    <a
      target='_blank'
      href={`mailTo:?subject=${encodeURI(
        `Lighthouse Bookshop: ${text}`
      )}&body=${text} (https://lighthousebookshop.com${url})`}>
      <svg
        className={className}
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth='2'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z' />
        <polyline points='22,6 12,13 2,6' />
      </svg>
    </a>
  </>
);
