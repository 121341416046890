import { priceAfterDiscount } from "lib/price-after-discount";
import { isNaN } from "lodash";
import { basketReducer } from "./reducer";
import { IBasket, IBasketItem } from "./types";

export const countBasketItems = (state: IBasket): number => {
  return state.items.reduce((acc, curr) => {
    return acc + curr.data.quantity;
  }, 0);
};

export const getBooksTotal = (state: IBasket): number => {
  const books = state.items.filter(
    (item) => item.type === "book"
  ) as IBasketItem<"book">[];
  const basketHasDiscount = !!state.discount;

  return books.reduce((acc, curr) => {
    if (
      state.discount?.applies_to.length === 0
        ? true
        : !!state.discount?.applies_to.includes(curr.id)
    ) {
      return (
        acc +
        priceAfterDiscount(
          curr.data.price,
          state.discount.discount_value,
          state.discount.is_fixed
        ) *
          curr.data.quantity
      );
    } else {
      return acc + (curr.data.price - curr.data.discount) * curr.data.quantity;
    }
  }, 0);
};

export const getProductsTotal = (state: IBasket): number => {
  const products = state.items.filter(
    (item) => item.type === "product"
  ) as IBasketItem<"product">[];
  return products.reduce((acc, curr) => {
    return acc + (curr.data.price - curr.data.discount) * curr.data.quantity;
  }, 0);
};

export const getTicketsTotal = (state: IBasket): number => {
  let vouchers = state.items.filter(
    (item) =>
      item.type === "ticket-voucher" || item.type === "ticket-online-voucher"
  ) as IBasketItem<"ticket-voucher" | "ticket-online-voucher">[];

  const books = state.items.filter(
    (item) => item.type === "ticket-book" || item.type === "ticket-online-book"
  ) as IBasketItem<"ticket-book" | "ticket-online-book">[];

  const vouchersTotal = vouchers.reduce((acc, curr) => {
    return acc + curr.data.price * curr.data.quantity ?? 0;
  }, 0);

  const booksTotal = books.reduce((acc, curr) => {
    return (
      acc + (curr.data.price - curr.data.discount) * curr.data.quantity ?? 0
    );
  }, 0);

  return vouchersTotal + booksTotal;
};

export const getVouchersTotal = (state: IBasket): number => {
  return state.items
    .filter((item) => item.type === "voucher")
    .reduce((acc, curr) => acc + curr.data["price"], 0);
};

export const getDiscountVouchersTotal = (state: IBasket): number => {
  return state.discount_vouchers.reduce(
    (acc, curr) => acc + curr.availableAmount,
    0
  );
};

export const getSubscriptionsTotal = (state: IBasket): number => {
  return state.items
    .filter((item) => item.type === "subscription")
    .reduce((acc, curr) => acc + curr.data["price"] * curr.data.quantity, 0);
};

export const getBasketTotal = (state: IBasket): number => {
  const pifTotal = !!state.pif && !isNaN(state.pif) ? state.pif : 0;

  const wrappingTotal = state.wrapping ?? 0;

  return Math.max(
    getBooksTotal(state) +
      getProductsTotal(state) +
      getTicketsTotal(state) +
      getVouchersTotal(state) +
      getSubscriptionsTotal(state) +
      pifTotal +
      wrappingTotal -
      getDiscountVouchersTotal(state),
    0
  );
};

export const basketHasDeliverables = (basket: IBasket): boolean => {
  return (
    basket.items.filter(
      (item) =>
        item.type !== "voucher" &&
        item.type !== "ticket-voucher" &&
        item.type !== "ticket-online-voucher" &&
        item.type !== "ticket-free" &&
        item.type !== "ticket-online-free" &&
        item.type !== "subscription"
    ).length > 0
  );
};

export const basketPurchasable = (basket: IBasket): boolean => {
  return basket.items.length > 0 || basket.pif > 0;
};

export const totalDeliverables = (basket: IBasket): number => {
  return basket.items
    .filter(
      (item) =>
        item.type === "book" ||
        item.type === "ticket-book" ||
        item.type === "ticket-online-book" ||
        item.type === "product" ||
        item.type === "subscription"
    )
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
};

export const removeOffendingItems = (
  basket: IBasket,
  reducer: typeof basketReducer,
  errorItems: { invalidBooks: number[]; invalidProducts: string[] }
): void => {
  errorItems.invalidBooks.forEach((isbn) => {
    let book = basket.items.find((item) => item.id === isbn);
    reducer(basket, {
      type: "remove",
      payload: { id: isbn, type: "book", data: { ...book.data } },
    });
  });

  errorItems.invalidProducts.forEach((prodId) => {
    let product = basket.items.find((item) => item.id === prodId);

    reducer(basket, {
      type: "remove",
      payload: { id: prodId, type: "product", data: { ...product.data } },
    });
  });
};

export const getTotalBasketItems = (basket: IBasket): number => {
  if (!basket) {
    return 0;
  }

  const totalBooks = basket.items
    .filter((item) => item.type === "book")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalProducts = basket.items
    .filter((item) => item.type === "product")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalSubs = basket.items
    .filter((item) => item.type === "subscription")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalVouchers = basket.items
    .filter((item) => item.type === "voucher")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalTicketBooks = basket.items
    .filter((item) => item.type === "ticket-book")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalTicketsFree = basket.items
    .filter((item) => item.type === "ticket-free")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalTicketsVoucher = basket.items
    .filter((item) => item.type === "ticket-voucher")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalTicketsOnlineBook = basket.items
    .filter((item) => item.type === "ticket-online-book")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalTicketsOnlineFree = basket.items
    .filter((item) => item.type === "ticket-online-free")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);
  const totalTicketsOnlineVoucher = basket.items
    .filter((item) => item.type === "ticket-online-voucher")
    .reduce((acc, curr) => acc + curr.data.quantity, 0);

  return (
    totalBooks +
    totalProducts +
    totalSubs +
    totalVouchers +
    totalTicketBooks +
    totalTicketsFree +
    totalTicketsVoucher +
    totalTicketsOnlineBook +
    totalTicketsOnlineFree +
    totalTicketsOnlineVoucher
  );
};
