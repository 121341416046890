export const HeartSVG = ({ className }) => (
  <svg
    className={className}
    id='list'
    version='1.1'
    viewBox='0 0 39.099755 36.456481'
    xmlns='http://www.w3.org/2000/svg'>
    <defs id='defs2' />
    <g id='layer1' transform='translate(-47.652462,-179.0316)'>
      <path
        id='path949'
        style={{ fill: '#000000', strokeWidth: '0.264583' }}
        d='m 63.469779,213.0473 c -3.65495,-2.74524 -9.214197,-8.12889 -11.276863,-10.92068 -2.664424,-3.60625 -4.540454,-8.3184 -4.540454,-11.40454 0,-8.0715 8.90025,-14.08192 15.926887,-10.75557 0.91735,0.43427 2.12954,1.1619 2.69376,1.61696 l 1.02586,0.82738 0.94599,-0.83058 c 2.31256,-2.03046 5.8553,-2.97396 8.84016,-2.3543 5.98142,1.24173 10.07017,6.69306 9.63547,12.84649 -0.36359,5.14676 -3.25039,10.15943 -9.07038,15.74991 -3.53872,3.39916 -9.35557,7.66571 -10.45114,7.66571 -0.26382,0 -1.942,-1.09835 -3.72929,-2.44078 z m 8.25977,-3.65565 c 8.10162,-6.12871 12.84036,-13.42072 12.33851,-18.98658 -0.43552,-4.83033 -4.36568,-8.58657 -9.0169,-8.61788 -1.61558,-0.0109 -2.08301,0.10354 -3.47818,0.85141 -0.88647,0.47519 -2.18333,1.44427 -2.8819,2.1535 l -1.27012,1.28952 -1.84725,-1.55492 c -2.62936,-2.21327 -3.86014,-2.7582 -6.18203,-2.73711 -4.550256,0.0413 -8.56069,3.64804 -9.005877,8.09927 -0.5732,5.73118 5.081481,13.79956 14.807867,21.12862 l 2.07262,1.56176 1.23944,-0.84322 c 0.68169,-0.46378 2.13241,-1.51874 3.22382,-2.34437 z'
      />
    </g>
  </svg>
);
