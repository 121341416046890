import { Button } from "components/buttons/button";
import { inputClassNames, StandardInput } from "components/forms/input";
import { SharingBar } from "components/sharing-bar";
import { useGetShopDetailsQuery } from "lib/graphql-generated";
import { useInput } from "lib/hooks/use-input";
import { emailRegex } from "lib/utils";
import Link from "next/link";
import { MouseEvent, useState } from "react";

export const Footer = () => {
  const { data } = useGetShopDetailsQuery();
  const email = useInput<string>("");
  const [subscribe, setSubscribe] = useState<boolean>(false);
  const [success, setSucces] = useState<boolean>(false);

  const handleSubscribe = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    fetch("/api/newsletter-signup", {
      method: "POST",
      body: JSON.stringify({
        email: email.value,
      }),
    }).then((res) => setSucces(res.status == 200));
  };

  return (
    <>
      <footer className='[ bg-blue-light flex mt-auto  ]  '>
        <article className='[ p-6 text-center md:text-left ] [ flex flex-row flex-wrap ] mx-auto max-w-screen-2xl'>
          <section className=' w-full py-6 md:w-1/2 md:px-3 2xl:flex-1 2xl:px-12'>
            <h2 className='font-header uppercase tracking-wide'>
              Find us on the high street
            </h2>
            <address className='not-italic text-sm'>
              {
                data?.unstructured_data.find((data) => data.title === "address")
                  .data.data
              }
            </address>
            <div className='inline-flex w-full space-x-4 pt-2'>
              <span className='text-sm'>Share:</span>
              <SharingBar
                className='h-4'
                url=''
                text="Checkout Lighthouse Bookshop, Edinburgh's Radical Bookshop"
              />
            </div>
          </section>
          <section className=' w-full py-6 md:w-1/2 md:px-3 2xl:flex-1 22xl:px-12'>
            <h2 className='font-header uppercase tracking-wide'>
              Opening Hours
            </h2>
            <p className='text-sm'>
              {
                data?.unstructured_data.find(
                  (data) => data.title === "opening_hours"
                ).data.data
              }
            </p>
          </section>
          <section className=' w-full py-6 md:w-1/2 md:px-3 2xl:flex-1 2xl:px-12'>
            <h2 className='font-header uppercase tracking-wide'>
              Get in touch
            </h2>
            <address className='not-italic text-sm'>
              email:{" "}
              <a
                className='underline hover:text-red-default'
                href='mailto:books@lighthousebookshop.com'>
                books@lighthousebookshop.com
              </a>
              <br />
              Phone:{" "}
              <a
                className='underline hover:text-red-default'
                href='tel:01316629112'>
                0131 662 9112
              </a>
            </address>
          </section>
          <form className=' w-full py-6 md:w-1/2 md:px-3 2xl:flex-1 2xl:px-12'>
            <h2 className='font-header uppercase tracking-wide'>Newsletter</h2>
            <p className='text-sm'>
              All the latest bookshop news & special offers
            </p>
            <StandardInput
              label='Email'
              name='email'
              id='email'
              type='email'
              bind={{ ...email.bind }}
            />
            <label htmlFor='sub' className='text-xs'>
              <input
                type='checkbox'
                name='sub'
                id='sub'
                checked={subscribe}
                onChange={(e) => setSubscribe(e.target.checked)}
                className='mr-2 text-base border border-gray-300 focus:outline-none focus:ring-yellow-default focus:border-yellow-default sm:text-sm rounded-md'
              />
              I agree to recieve this newsletter!
            </label>
            <Button
              buttonType='secondary'
              className='mt-2'
              onClick={handleSubscribe}
              disabled={!subscribe || !emailRegex.test(email.value) || success}>
              {success ? "Success!" : "Subscribe"}
            </Button>
          </form>
          <section className=' w-full py-6 md:w-1/2 md:px-3 2xl:flex-1 2xl:px-12 '>
            <h2 className='font-header uppercase tracking-wide'>Site Map</h2>
            <ul className='text-sm'>
              <li>
                <Link href='/terms-conditions'>
                  <a className='underline hover:text-red-default'>
                    Terms and Conditions
                  </a>
                </Link>
              </li>
            </ul>
          </section>
          <section className='text-left text-xs text-gray-600 w-full py-6 md:w-1/2 md:px-3 md:text-center lg:w-full flex flex-col space-y-2'>
            <div>&#169; 2020 Lighthouse - Edinburgh's Radical Bookshop </div>
            <div>
              🎨 Designed by:{` `}
              <a
                className='border-b-2 border-yellow-default font-bold hover:bg-yellow-default hover:text-white px-1 pt-0.5'
                href='https://www.topsycoriandesign.co.uk/'
                target='_blank'>
                Topsy Corian Design (Sarah Lewis)
              </a>
            </div>
            <div>
              💻 Developed by:{" "}
              <a
                className='border-b-2 border-yellow-default font-bold hover:bg-yellow-default hover:text-white  px-1 pt-0.5'
                href='https://humont.dev'
                target='_blank'>
                Hugo Montagne
              </a>
            </div>
          </section>
        </article>
      </footer>
    </>
  );
};
