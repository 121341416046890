import { convertToHTML } from "draft-convert";
import { convertFromRaw, RawDraftContentState } from "draft-js";
import { constants } from "components/text-editor/editor-utils";
import { aElement } from "./a";
import { getContent } from "lib/draft-convert";

interface Props {
  content: string | RawDraftContentState;
  books?: any[];
  darkBackground?: boolean;
}
export const RenderContent = ({ content, books, darkBackground }: Props) => {
  try {
    if (typeof content === "string") {
      return getContent(JSON.parse(content), darkBackground);
    } else {
      return getContent(content, darkBackground);
    }
  } catch (e) {
    return null;
  }
};
